import React from 'react';

function Card(props: {children: React.ReactNode; variant?: 'blue' | 'yellow'}) {
    let bgColor: string;

    switch (props.variant) {
        case 'yellow':
            bgColor = 'bg-noyellow';
            break;
        case 'blue':
        default:
            bgColor = 'bg-noblue';
            break;
    }
    return (
        <div className='relative w-full pb-1 pr-1'>
            <div className='relative z-10 block w-full h-full p-3 bg-white shadow flex-col-left'>
                {props.children}
            </div>
            <div
                className={
                    'absolute top-0 left-0 z-0 block transform translate-x-1 translate-y-1 shadow ' +
                    `${bgColor} `
                }
                style={{
                    width: 'calc(100% - 0.25rem)',
                    height: 'calc(100% - 0.25rem)',
                }}
            />
        </div>
    );
}

export default Card;
